import React from "react";

import { Button, Row, Col, Space, message, Table, Spin, Input } from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import Modal from "antd/lib/modal/Modal";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { operations, selectors } from "../../redux";
import { hashStrFormat, timeStrFormat } from "../../tools/format";
import IconComponent from "../../components/Icon";
import {
  cachePageScrollTop,
  scrollToCachedPosition,
} from "../../tools/page_scroll";
import { AppState } from "../../redux/reducer";
import Config from "../../config";
import { get } from "../../tools/request";
import { Icon, Flex } from "../../components";
import "../../static/css/home.css";
import title_img from "../../static/images/title.png";
import icon_1 from "../../static/images/icon/icon_1.png";
import icon_2 from "../../static/images/icon/icon_2.png";
import icon_3 from "../../static/images/icon/icon_3.png";
import icon_4 from "../../static/images/icon/icon_4.png";

interface Props extends RouteComponentProps {
  currentChannel: string;
  latestBlock?: any[];
  latestTransaction?: any[];
  dashStats: any;
}

interface State {
  keyword: string;
  searching: boolean;
  showPeerModal: boolean;
  peers: any[];
  perrsLoading: boolean;
}
class HomePage extends React.PureComponent<Props> {
  state: State;
  constructor(props: Props) {
    super(props);
    this.state = {
      keyword: "",
      searching: false,
      showPeerModal: false,
      peers: [],
      perrsLoading: false,
    };
  }
  componentDidMount() {
    scrollToCachedPosition("home");
  }

  componentWillUnmount() {
    cachePageScrollTop("home");
  }

  handleSearch = async () => {
    const { keyword, searching } = this.state;
    const { history } = this.props;
    if (!keyword || keyword.length <= 0) {
      return;
    }
    if (searching) return;

    this.setState({ searching: true });
    let _keyword: any = encodeURIComponent(keyword.trim());
    if (!isNaN(_keyword) && _keyword >= 1000000000) {
      message.error("区块高度不能超过 1000000000");
      this.setState({ searching: false });
      return;
    }

    try {
      const result = await this.search(_keyword);
      this.setState({ searching: false });
      // 根据搜索结果类型跳转页面
      switch (result.type) {
        case "block":
          history.push({
            pathname: `/block/${result.blockhash || _keyword}`,
            state: { blockDetails: result },
          });
          break;
        case "transaction":
          history.push({
            pathname: `/transaction/${result.txhash || _keyword}`,
            state: { transactionDetails: result },
          });
          break;
        default:
          message.error("没有匹配的搜索类型");
          break;
      }
    } catch (error) {
      this.setState({ searching: false });
      message.error(error);
    }
  };

  search = (keyword: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      get(`/api/blockDetail?keyWord=${keyword}`)
        .then((resp: any) => {
          if (resp.status === 500) {
            reject(
              "500 Internal Server Error: The server has encountered an internal error and unable to complete your request"
            );
          } else if (resp.status === 400) {
            reject(resp.error);
          } else {
            if (resp.data) {
              resolve(resp.data);
            } else {
              reject("未搜索到相应区块/存证");
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _val = e.target.value;
    this.setState({ keyword: _val });
  };

  handleShowPeer = async () => {
    const { peers, showPeerModal } = this.state;
    if (showPeerModal) return;

    this.setState({ showPeerModal: true });
    if (!peers || peers.length <= 0) {
      this.setState({ perrsLoading: true });
      this.loadPeers()
        .then((peers) => {
          this.setState({
            peers,
          });
        })
        .catch((error) => message.error(error))
        .finally(() => this.setState({ perrsLoading: false }));
    }
  };

  loadPeers = async () => {
    const { currentChannel } = this.props;
    return new Promise((resolve, reject) => {
      get("/api/peers/" + currentChannel)
        .then((resp: any) => {
          if (resp.status === 200 && resp.peers) {
            let peers = [];
            for (let i = 0; i < resp.peers.length; i++) {
              const peer = resp.peers[i];
              if (peer.peer_type === "PEER") {
                peers.push({
                  key: i,
                  ...peer,
                });
              }
            }
            resolve(peers);
          } else {
            reject("获取节点失败");
          }
        })
        .catch((error) => {
          reject("获取节点失败:" + error);
        });
    });
  };

  /**
   * 构建数据状态模块
   */
  buildDashStats() {
    const { history, dashStats } = this.props;
    const { showPeerModal, peers, perrsLoading } = this.state;
    if (!dashStats) return null;

    function buildDashStatsItem(item: {
      name: string;
      data: number;
      icon?: React.ReactNode;
      onTap?: () => void;
    }) {
      return (
        <Col span={12} md={6}>
          <Flex
            className="dash-stats-item"
            jusify="center"
            onClick={item.onTap}
          >
            <div className="dash-states-icon">{item.icon}</div>
            <Flex.Item style={{ color: "#353535" }}>
              <div className="dash-stats-number">{item.data || 0}</div>
              <Flex>
                <span>{item.name}</span>
                {item.onTap && (
                  <Icon style={{ marginLeft: 6 }}>arrow_right</Icon>
                )}
              </Flex>
            </Flex.Item>
          </Flex>
        </Col>
      );
    }

    const peerColumns: ColumnsType = [
      {
        title: "节点",
        dataIndex: "server_hostname",
        key: "server_hostname",
      },
      {
        title: "节点名称",
        dataIndex: "mspid",
        key: "mspid",
        render: (text) => Config.getOrgName(text),
      },
    ];

    return (
      <div className="dash-stats-container">
        <Modal
          visible={showPeerModal}
          centered={true}
          title={null}
          closable={false}
          width={460}
          bodyStyle={{ padding: 0 }}
          onCancel={() => this.setState({ showPeerModal: false })}
          footer={null}
        >
          <Spin spinning={perrsLoading}>
            <Table
              dataSource={peers}
              columns={peerColumns}
              pagination={false}
            />
          </Spin>
        </Modal>
        <Row>
          {buildDashStatsItem({
            name: "区块数量",
            data: dashStats.latestBlock,
            icon: <img src={icon_1} alt="icon" />,
            onTap: () => {
              history.push("/block");
            },
          })}
          {buildDashStatsItem({
            name: "存证数量",
            data: dashStats.txCount,
            icon: <img src={icon_2} alt="icon" />,
            onTap: () => {
              history.push("/transaction");
            },
          })}
          {buildDashStatsItem({
            name: "节点数量",
            data: parseInt(dashStats.peerCount || 0) + 3,
            icon: <img src={icon_3} alt="icon" />,
            // onTap: this.handleShowPeer
          })}
          {buildDashStatsItem({
            name: "合约数量",
            icon: <img src={icon_4} alt="icon" />,
            data: dashStats.chaincodeCount,
          })}
        </Row>
      </div>
    );
  }

  /**
   * 构建最新区块模块
   */
  buildLatestBlock() {
    const { latestBlock } = this.props;
    if (!latestBlock || latestBlock.length <= 0) return null;

    const _latestBlockList = latestBlock.slice(0, 6);
    return (
      <div className="latest-block-container">
        <Flex className="header">
          <Flex.Item>
            <h3 style={{ margin: "0" }}>最新区块</h3>
          </Flex.Item>
          <Button type="link">
            <Link to="/block">查看全部</Link>
          </Button>
        </Flex>
        <div>
          <Space direction="vertical" style={{ width: "100%" }}>
            {_latestBlockList.map((block, index) => (
              <BlockItem key={index} data={block} />
            ))}
          </Space>
        </div>
      </div>
    );
  }

  buildLatestTransaction() {
    const { latestTransaction } = this.props;
    if (!latestTransaction || latestTransaction.length <= 0) return null;

    const _latestTransactionList = latestTransaction.slice(0, 6);
    return (
      <div className="latest-transaction-container">
        <Flex className="header">
          <Flex.Item>
            <h3 style={{ margin: "0" }}>最新存证</h3>
          </Flex.Item>
          <Button type="link">
            <Link to="/transaction">查看全部</Link>
          </Button>
        </Flex>
        <div>
          <Space direction="vertical" style={{ width: "100%" }}>
            {_latestTransactionList.map((transaction, index) => (
              <TransactionItem
                key={index}
                data={transaction}
                index={index + 1}
              />
            ))}
          </Space>
        </div>
      </div>
    );
  }

  render() {
    const { keyword, searching } = this.state;
    return (
      <div className="home">
        <div className="bg_banner" />
        <div className="container">
          <div className="title xs-down">
            <img src={title_img} alt="title" />
          </div>
          <div className="search-container">
            <div className="search-box">
              <Input.Search
                placeholder="区块高度/区块哈希/存证哈希/文件哈希"
                value={keyword}
                onChange={this.handleInputChange}
                onSearch={this.handleSearch}
                enterButton="搜索"
                size="large"
                loading={searching}
              />
            </div>
          </div>

          {this.buildDashStats()}

          <div style={{ margin: "2em 0" }}>
            <Row>
              <Col span="24" lg={12}>
                {this.buildLatestBlock()}
              </Col>
              <Col span="24" lg={12}>
                {this.buildLatestTransaction()}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state: AppState) => ({
  currentChannel: selectors.currentChannelSelector(state),
  latestBlock: selectors.latestBlockSelector(state),
  latestTransaction: selectors.latestTransactionSelector(state),
  dashStats: selectors.dashStatsSelector(state),
}))(withRouter(HomePage));

const BlockItem: React.FC<{ data: any }> = ({ data }) => {
  if (!data) return null;
  return (
    <Flex className="block-item">
      <div className="code">{data.blocknum}</div>
      <Flex style={{ fontSize: "0.85em", width: "100%" }}>
        <Flex direction="column" align="start">
          <div>
            <label>区块哈希：</label>
            <Link to={`/block/${data.blockhash}`}>
              {hashStrFormat(data.blockhash)}
            </Link>
          </div>
          <div>
            <label>出块时间：</label>
            <span>{timeStrFormat(data.createdt)}</span>
          </div>
          <div className="xs-up">
            <label>存证数量：</label>
            <span>{data.txcount}</span>
          </div>
        </Flex>
        <Flex.Item className="xs-down">
          <Flex direction="column" align="center">
            <span>存证数量</span>
            <span className="primary-color">{data.txcount}</span>
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  );
};
const TransactionItem: React.FC<{ data: any; index: number }> = ({
  data,
  index,
}) => {
  if (!data) return null;
  return (
    <Flex className="transtion-item">
      <div className="code">{index}</div>
      <Flex.Item>
        <Flex style={{ fontSize: "0.9em" }}>
          <Flex direction="column" align="start">
            <div>
              <label>存证哈希：</label>
              <Link to={`/transaction/${data.txhash}`}>
                {hashStrFormat(data.txhash)}
              </Link>
            </div>
            <div>
              <label>出块时间：</label>
              <span>{timeStrFormat(data.createdt)}</span>
            </div>
            <div className="xs-up">
              <label>发起方：</label>
              <span className="primary-color">
                {Config.getOrgName(data.creator_msp_id)}
              </span>
            </div>
            <div className="xs-up">
              <label>合约：</label>
              <span className="primary-color">{data.chaincodename}</span>
            </div>
          </Flex>
          <Flex.Item className="xs-down">
            <Flex direction="column" align="start">
              <div>
                <label>发起方：</label>
                <span className="primary-color">
                  {Config.getOrgName(data.creator_msp_id)}
                </span>
              </div>
              <div>
                <label>合约：</label>
                <span className="primary-color">{data.chaincodename}</span>
              </div>
            </Flex>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </Flex>
  );
};
