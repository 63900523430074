import React, { CSSProperties } from 'react';
import '../static/css/components/card.css';

/**
 * 圆角带阴影卡片容器组件
 * 
 */
const CardComponent: React.FC<{
	style?: CSSProperties;
	className?: string;
}> = ({ children, style, className }) => {
	const classes = className ? 'card-component ' + className : 'card-component';
	const styles = style ? style : undefined;

	return (
		<div className={classes} style={styles}>
			{children}
		</div>
	);
};

export default CardComponent;
