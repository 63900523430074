/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import types from './types';

export interface BlockListState {
	rows: any[];
	page: number;
	total: number;
}

export interface TransactionListState {
	rows: any[];
	page: number;
	total: number;
}

export interface AppState {
	[key: string]: any;
	blockList?: BlockListState;
	transactionList?: TransactionListState;
	latestBlock: any[];
	latestTransaction: any[];
}

const initialState: AppState = {
	blockList: {
		page: 1,
		rows: [],
		total: 0
	},
	transactionList: {
		page: 1,
		rows: [],
		total: 0
	},
	latestBlock: [],
	latestTransaction: []

};

const appReducers = (state = initialState, action: any) => {

	switch (action.type) {
		case types.AUTH_TOKEN:
			return {
				authToken: action.payload,
				...state
			};
		case types.CURRENT_CHANNEL:
			return {
				currentChannel: action.payload,
				...state
			};
		case types.ERROR_MESSAGE:
			return {
				errorMessage: action.payload,
				...state
			};

		case types.DASHBOARD_STATS:
			return { dashStats: action.payload, ...state };

		case types.BLOCK_LIST: {
			let newStore = { ...state }
			newStore.blockList = action.payload
			return newStore
		}

		case types.BLOCK_DETAILS: {
			return {
				blockDetails: action.payload,
				...state
			};
		}

		case types.TRANSACTION_LIST: {
			let newStore = { ...state }
			newStore.transactionList = action.payload
			return newStore
		}


		case types.LATEST_BLOCK: {
			let newStore = { ...state }
			newStore.latestBlock = action.payload
			return newStore;
		}

		case types.LATEST_TRANSACTION: {
			let newStore = { ...state }
			newStore.latestTransaction = action.payload
			return newStore;
		}

		default:
			return state;
	}
};

export default appReducers;
