import React from 'react';
import { Table, Button } from 'antd';

import '../../static/css/transaction.css';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { BreadcrumbComponent } from '../../components';
import { connect } from 'react-redux';

import { operations, selectors } from '../../redux';
import { numberFormat, timeStrFormat, hashStrFormat } from '../../tools/format';
import { cachePageScrollTop, scrollToCachedPosition } from '../../tools/page_scroll';
import Config from '../../config';
import { TransactionListState, AppState } from '../../redux/reducer';

const columns: ColumnsType<any> = [
	{
		title: '发起者',
		dataIndex: 'creator_msp_id',
		key: 'creator_msp_id',
		render: (text) => Config.getOrgName(text)
	},

	{
		title: '存证哈希',
		dataIndex: 'txhash',
		key: 'txhash',
		render: (text) => <Link to={`/transaction/${text}`}>{hashStrFormat(text, 40)}</Link>
	},
	{
		title: '状态',
		dataIndex: 'type',
		key: 'type',
		render: () => '已确认'
	},
	{
		title: '合约',
		dataIndex: 'chaincodename',
		key: 'chaincodename'
	},
	{
		title: '时间',
		dataIndex: 'createdt',
		key: 'createdt',
		render: (text) => timeStrFormat(text)
	}
];
interface State {
	loading: boolean;
}
class TransactionPage extends React.Component<{
	transactionList: TransactionListState;
	currentChannel: string;
	getTransactionList: (page: number, prePage: number) => Promise<void>;
}> {
	state: State;
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false
		};
	}

	async componentDidMount() {
		scrollToCachedPosition('transaction');
		const { transactionList } = this.props;
		if (transactionList.rows.length <= 0) {
			this.loadTransactions(transactionList.page);
		}
	}

	async loadTransactions(page: number, prePage: number = 15) {
		const { getTransactionList } = this.props;
		this.setState({ loading: true });
		await getTransactionList(page, prePage);
		this.setState({ loading: false });
	}
	componentWillUnmount() {
		cachePageScrollTop('transaction');
	}

	handlePageChange = (page: number, pageSize?: number) => {
		this.loadTransactions(page, pageSize);
	};

	render() {
		const { transactionList } = this.props;
		
		return (
			<div className="container transaction">
				<BreadcrumbComponent data={[ { name: '存证信息', href: '/transaction' } ]} />

				<div className="table-container">
					{transactionList && <div className="total">全部存证（{numberFormat(transactionList.total)}个存证）</div>}
					<Table
						size="small"
						scroll={{ x: 860 }}
						dataSource={transactionList && transactionList.rows || []}
						columns={columns}
						loading={this.state.loading}
						rowKey="id"
						pagination={{
							total: transactionList && transactionList.total || 0,
							current: transactionList &&transactionList.page || 1,
							position: [ 'topRight', 'bottomRight' ],
							defaultPageSize: 15,
							hideOnSinglePage: true,
							onChange: this.handlePageChange,
							showQuickJumper: {
								goButton: (
									<Button size="small" type="primary" style={{ fontSize: 13, marginLeft: 6 }}>
										跳转
									</Button>
								)
							},
							pageSizeOptions: [ '15', '30', '45', '60' ]
						}}
					/>
				</div>
			</div>
		);
	}
}

export default connect(
	(state: AppState) => ({
		transactionList: selectors.transactionListSelector(state),
		currentChannel: selectors.currentChannelSelector(state)
	}),
	{
		getTransactionList: operations.transactionList
	}
)(TransactionPage);
