/**
 *    SPDX-License-Identifier: Apache-2.0
 */
import agent from 'superagent';

import Auth from './auth';
import { message } from 'antd';
import Config from "../config"

interface PayloadType {
	[name: string]: any;
}

export const post = (uri: string, payload: PayloadType) =>
	new Promise((resolve, reject) => {
		agent
			.post(Config.getApiRoot() + uri)
			.send(payload)
			.set('Accept', 'application/json')
			.set('Accept', 'application/json')
			.set('Authorization', `bearer ${Auth.getToken()}`)
			.end(withPromiseCallback(resolve, reject));
	});
export const get = (uri: string) =>
	new Promise((resolve, reject) => {
		agent
			.get(Config.getApiRoot() + uri)
			.set('Accept', 'application/json')
			.set('Authorization', `bearer ${Auth.getToken()}`)
			.end(withPromiseCallback(resolve, reject));
	});
export const put = (uri: string, payload: PayloadType) =>
	new Promise((resolve, reject) => {
		agent
			.put(Config.getApiRoot() + uri)
			.send(payload)
			.set('Accept', 'application/json')
			.set('Authorization', `bearer ${Auth.getToken()}`)
			.end(withPromiseCallback(resolve, reject));
	});
export const deleteRequest = (uri: string, payload: PayloadType) =>
	new Promise((resolve, reject) => {
		agent
			.delete(Config.getApiRoot() + uri)
			.send(payload)
			.set('Accept', 'application/json')
			.set('Authorization', `bearer ${Auth.getToken()}`)
			.end(withPromiseCallback(resolve, reject));
	});

export const withPromiseCallback = (resolve: (value?: unknown) => void, reject: (reason?: any) => void) => (
	error: any,
	response: any
) => {
	if (error) {
		if (response) {
			if (response.status !== 200) {
				if (response.status === 401) {
					Auth.deauthenticateUser();
					window.location.reload()
				} else {
					message.error(`${response.status}: ${response.statusText}`);
				}
			}
		} else {
			message.error('服务器无响应');
		}
		reject({ error });
	} else {
		resolve(response.body);
	}
};
