import React, { useState, useCallback } from 'react';
import { Drawer } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Flex, Icon } from '../components';

import logo from "../static/images/logo.png";
import logo_white from "../static/images/logo_white.png";

const MENU_LIST = [
	{
		name: '首页',
		to: '/home'
	},
	{
		name: '区块链',
		to: '/block'
	},
	{
		name: '存证信息',
		to: '/transaction'
	}
];

const LayoutHeader: React.FC = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [showDrawer, setShwoDrawer] = useState(false);

	const handleMenuIconClick = useCallback(
		() => {
			if (!showDrawer) {
				setShwoDrawer(true);
			}
		},
		[showDrawer]
	);
	const IS_HOME_PAGE = pathname === '/home';

	return (
		<header className={IS_HOME_PAGE ? 'dark' : 'light'}>
			<Flex className="container" jusify="between">
				<div className="logo" >
					<Link to="/home" className="sm-down">
						<img
							title="返回首页"
							src={IS_HOME_PAGE ? logo_white : logo}
							alt="logo.png"
						/>
					</Link>
				</div>
				<div className="menu-container sm-down">
					<ul>
						{MENU_LIST.map((menu, index) => {
							const active = pathname === menu.to;
							return (
								<li key={index} className={`menu-item${active ? ' active' : ''}`}>
									<Link to={menu.to}>{menu.name}</Link>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="menu-icon-container sm-up">
					<Flex className="menu-icon" align="center" jusify="center" onClick={handleMenuIconClick}>
						<Icon style={{ fontSize: 24 }}>menu</Icon>
					</Flex>
					<Drawer
						title={<img src={logo} alt="logo.png" style={{height: 48, width: 'auto'}} />}
						className="menu-drawer sm-up"
						placement="right"
						closable={false}
						visible={showDrawer}
						onClose={() => setShwoDrawer(false)}
					>
						<ul>
							{MENU_LIST.map((menu, index) => {
								const active = pathname === menu.to;
								return (
									<li
										key={index}
										className={`${active ? 'active' : ''}`}
										onClick={() => {
											setShwoDrawer(false);
											history.push(menu.to);
										}}
									>
										{menu.name}
									</li>
								);
							})}
						</ul>
					</Drawer>
				</div>
			</Flex>
		</header>
	);
};

export default LayoutHeader;
