import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from '../../tools/request';
import { CardComopnent, BreadcrumbComponent, Flex } from '../../components';
import { connect } from 'react-redux';
import { selectors } from '../../redux';

import '../../static/css/transaction.css';
import { scrollToTop } from '../../tools/page_scroll';
import { timeStrFormat } from '../../tools/format';
import Config from '../../config';
import { message, Spin } from 'antd';

interface State {
	transactionDetails: any;
	loading: boolean;
}
interface Props extends RouteComponentProps<{ id: string }, {}, { transactionDetails: any }> {
	currentChannel: string;
}
class TransactionInfoPage extends React.Component<Props> {
	state: State;
	constructor(props: Props) {
		super(props);
		this.state = {
			transactionDetails: {},
			loading: false
		};
	}

	componentWillMount() {
		const { match, location } = this.props;
		if (location.state && location.state.transactionDetails) {
			this.setState({ transactionDetails: location.state.transactionDetails })
		} else {
			const hash = match.params.id;
			this.getTransactionDetails(hash.trim());
		}
		scrollToTop();
	}

	getTransactionDetails = (transactionId: string) => {
		const channel = this.props.currentChannel;
		if(!channel) {
			message.error("未获取到通道信息，请刷新页面重试")
			return;
		}
		this.setState({ loading: true });
		get(`/api/transaction/${channel}/${transactionId}`)
			.then((resp: any) => {
				if (resp.status === 500) {
					message.error(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				} else if (resp.status === 400) {
					message.error(resp.error)
				} else {
					if (resp.data !== null) {
						this.setState({
							transactionDetails: resp.row
						});
					} else {
						message.error('存证信息不存在');
					}
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	/**
	 * 渲染背书人
	 * 
	 * @param str 
	 */
	renderEndorser(str: string = '') {
		const _str = str.replace(/[{|}|"]/g, '');
		const _arr: string[] = _str.split(',');

		return _arr.map((item: any) => Config.getOrgName(item)).toString();
	}

	render() {
		const { transactionDetails } = this.state;

		return (
			<div className="container transaction-info">
				<Spin delay={200} size="large" spinning={this.state.loading}>
					<BreadcrumbComponent data={[{ name: '存证信息', href: '/transaction' }, { name: '存证详情' }]} />
					

					{/* 概况 */}
					<CardComopnent className="overview">
						<div className="card-title">概况</div>
						<ul>
							<li>
								<RadioIcon color="#F092F0" />
								<label>存证哈希</label>
								<span className="text-ellipsis">{transactionDetails.txhash || '--'}</span>
							</li>
							{transactionDetails.filehash && transactionDetails.filehash.length > 10 &&
								<li>
									<RadioIcon color="#F092F0" />
									<label>文件哈希</label>
									<span className="text-ellipsis">{transactionDetails.filehash}</span>
								</li>
							}
							<li>
								<RadioIcon color="#6ED0AB" />
								<label>区块高度</label>
								<span>{transactionDetails.blocknum || '--'}</span>
							</li>
							<li>
								<RadioIcon color="#6ED0AB" />
								<label>状态</label>
								<span>{transactionDetails.txhash ? '已确认' : '--'}</span>
							</li>
							<li>
								<RadioIcon color="#049CFF" />
								<label>通道名称</label>
								<span>{transactionDetails.channelname || '--'}</span>
							</li>
							<li>
								<RadioIcon color="RGBA(251, 92, 154, 1)" />
								<label>发起者</label>
								<span>
									{transactionDetails.creator_msp_id ? (
										Config.getOrgName(transactionDetails.creator_msp_id)
									) : (
											'--'
										)}
								</span>
							</li>
							<li>
								<RadioIcon color="#C460E1" />
								<label>背书者</label>
								<span>
									{transactionDetails.endorser_msp_id ? (
										this.renderEndorser(transactionDetails.endorser_msp_id)
									) : (
											'--'
										)}
								</span>
							</li>
							<li>
								<RadioIcon color="#FE6C00" />
								<label>合约</label>
								<span>{transactionDetails.chaincodename || '--'}</span>
							</li>
							<li>
								<RadioIcon color="#FE6C00" />
								<label>时间</label>
								<span>{timeStrFormat(transactionDetails.createdt)}</span>
							</li>
						</ul>
					</CardComopnent>

				</Spin>
			</div>
		);
	}
}

export default connect((state) => ({
	currentChannel: selectors.currentChannelSelector(state)
}))(withRouter(TransactionInfoPage));

const RadioIcon: React.FC<{ color?: string }> = ({ color }) => {
	return (
		<Flex style={{ height: 40, width: 40, flexShrink: 0 }} align="center" jusify="center">
			<svg className="icon" style={{ width: 18, height: 18 }} viewBox="0 0 1024 1024" version="1.1" p-id="1140">
				<path
					d="M511.392323 217.225051c-82.075152 2.534141-151.518384 31.573333-207.075555 87.130505-55.544242 55.557172-84.596364 125.000404-88.384647 208.329697 2.534141 83.329293 31.573333 152.772525 87.130505 208.329696s125.000404 84.596364 208.329697 87.130506c83.330586-2.534141 152.772525-31.573333 208.329697-87.130506 55.555879-55.557172 84.596364-125.000404 87.129212-208.329696-2.532848-83.329293-31.573333-152.772525-87.129212-208.329697-55.558465-55.557172-125.000404-84.596364-208.329697-87.130505z m0-53.023031c98.481131 2.521212 180.557576 36.615758 246.211233 102.270707 65.656242 65.654949 99.749495 147.730101 102.273292 246.212526-2.522505 98.482424-36.615758 180.557576-102.273292 246.212525-65.653657 65.654949-147.730101 99.749495-246.211233 102.272-98.482424-2.521212-180.557576-36.615758-246.212525-102.272C199.524848 693.244121 165.430303 611.167677 162.909091 512.685253c2.521212-98.482424 36.615758-180.557576 102.270707-246.212526 65.654949-65.654949 147.730101-99.749495 246.212525-102.270707z m0 147.730101c56.825535 1.254141 103.537778 21.462626 141.420606 59.332525 37.868606 37.882828 58.078384 84.596364 59.331233 141.420607-1.252848 56.824242-21.462626 103.537778-59.331233 141.407676-37.884121 37.882828-84.596364 58.091313-141.420606 59.344162-56.824242-1.254141-103.537778-21.462626-141.407677-59.344162-37.882828-37.869899-58.091313-84.583434-59.345454-141.407676 1.254141-56.824242 21.462626-103.537778 59.345454-141.420607 37.869899-37.869899 84.583434-58.078384 141.407677-59.332525z"
					fill={color ? color : '#353535'}
				/>
			</svg>
		</Flex>
	);
};
