/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component, CSSProperties } from 'react';
import { connect } from 'react-redux';
import Logo from '../static/images/logo.png';
import { operations, selectors } from '../redux';
import { Flex } from '../components';
import {  message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Config from '../config';

interface State {
	hasDbError: boolean;
}

class loadingPage extends Component<{
	currentChannel: string;
	auth: string;
	onLoaded: () => void;
	login: (loginInfo: { [name: string]: string }) => Promise<any>;
	getChannel: () => Promise<void>;
	getBlockList: (page: number, prePage: number) => Promise<void>;
	getTransactionList: (page: number, prePage: number) => Promise<void>;
	getDashStats: (channel: string) => Promise<void>;
}> {
	state: State;
	constructor(props: any) {
		super(props);
		this.state = {
			hasDbError: false
		};
	}

	async componentDidMount() {
		const { auth, login } = this.props;
		if (!auth) {
			const loginResult = await login({
				user: 'dummy-user',
				password: 'dummy-password',
				network: Config.getNetwork()
			});
			if (loginResult.status === 'Success') {
				this.initData();
			} else {
				message.error(loginResult.message);
			}
		} else {
			this.initData();
		}
	}
	async initData() {
		const { getChannel, getBlockList, getDashStats, getTransactionList, onLoaded } = this.props;
		await getChannel();
		const { currentChannel } = this.props;

		const promiseTimeout = setTimeout(() => {
			this.setState({ hasDbError: true });
		}, 60000);

		await Promise.all([ getDashStats(currentChannel), getBlockList(1, 15), getTransactionList(1, 15) ]);
		clearTimeout(promiseTimeout);
		onLoaded();
	}

	render() {
		const { hasDbError } = this.state;
		const contentStyle: CSSProperties = {
			color: '#353535',
			lineHeight: '160px'
		};
		if (hasDbError) {
			return (
				<Flex
					style={{
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<h1>Please verify your network configuration, database configuration and try again</h1>
				</Flex>
			);
		}

		return (
			<div
				style={{
					height: '100vh',
					width: '100%',
					padding: '35vh 32px 0',
					textAlign: 'center',
					maxWidth: '460px',
					margin: 'auto',
					color: '#353535'
				}}
			>
				<img src={Logo} alt="Hyperledger Logo" style={{ marginBottom: 40, width: '100%' }} />
				<div>
					<Spin indicator={<LoadingOutlined style={{fontSize: 36}} />} />
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentChannel: selectors.currentChannelSelector(state),
		auth: selectors.authSelector(state)
	}),
	{
		login: operations.login,
		getChannel: operations.channel,
		getBlockList: operations.blockList,
		getDashStats: operations.dashStats,
		getTransactionList: operations.transactionList
	}
)(loadingPage);
