/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import reducer from './reducer';
// import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as operations from './operations';
import * as selectors from './selectors';
import Auth from '../tools/auth';

export { operations, selectors };

export default function initStore(initialState?: any) {
	// const rootReducer = combineReducers(reducer);
	const token = Auth.getToken();

	return createStore(reducer, { ...initialState, authToken: token }, applyMiddleware(thunkMiddleware));
}
