import React from 'react';

import { CardComopnent, BreadcrumbComponent, Icon, Flex } from '../../components';

import '../../static/css/block.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from '../../tools/request';
import { timeStrFormat, hashStrFormat } from '../../tools/format';
import { Link } from 'react-router-dom';
import Table, { ColumnsType } from 'antd/lib/table';
import { scrollToTop } from '../../tools/page_scroll';
import { Spin, Button, message } from 'antd';
import Config from '../../config';
import { connect } from 'react-redux';
import { selectors } from '../../redux';

const columns: ColumnsType<any> = [
	{
		title: '',
		width: 16
	},
	{
		title: '交易哈希',
		dataIndex: 'txhash',
		key: 'txhash',
		render: (text) => text ? <Link to={`/transaction/${text}`}>{hashStrFormat(text)}</Link> : '-'
	},
	{
		title: '发起者',
		dataIndex: 'creator_msp_id',
		key: 'creator_msp_id',
		render: (text) => Config.getOrgName(text) || '-'
	},
	// {
	// 	title: '',
	// 	width: 100,
	// 	align: 'center',
	// 	render: () => (<Flex align="center" jusify="center" style={{background: '#353535', width: 22, height: 22, borderRadius: '50%'}}><Icon style={{color: '#fff'}}>arrow_right_2</Icon></Flex>)
	// },
	{
		title: '合约',
		dataIndex: 'chaincodename',
		key: 'chaincodename',
		render: (text) => text || '-'
	},
	{
		title: '状态',
		dataIndex: 'status',
		key: 'status',
		render: () => '有效'
	},
	{
		title: '时间',
		dataIndex: 'createdt',
		key: 'createdt',
		render: (text) => timeStrFormat(text)
	},
	{
		title: '',
		width: 16
	}
];

interface State {
	blockDetails: any;
	loading: boolean;
}
interface Props extends RouteComponentProps<{ id: string },{},{blockDetails: any}> {
	currentChannel: string;
}

class BlockInfoPage extends React.Component<Props> {
	state: State;
	constructor(props: Props) {
		super(props);
		this.state = {
			blockDetails: {},
			loading: false
		};
	}

	componentWillMount() {
		const { match, location } = this.props;
		if(location.state && location.state.blockDetails) {
			this.setState({blockDetails: location.state.blockDetails})
		}else {
			const _id = match.params.id;
			this.getBlockDetails(_id.trim());
		}
		scrollToTop();
	}

	componentWillReceiveProps(props: Props) {
		const _newId = props.match.params.id;
		const _oldId = this.props.match.params.id;

		if (_oldId !== _newId) {
			this.getBlockDetails(_newId);
		}
	}

	getBlockDetails = async (_id: any) => {
		const channel = this.props.currentChannel;
		if(!channel) {
			message.error("未获取到通道信息，请刷新页面重试")
			return;
		}
		this.setState({ loading: true });
		const url = isNaN(_id) ? `/api/blockByHash/${channel}/${_id}` : `/api/blockByNumber/${channel}/${_id}`
		get(url)
			.then((resp: any) => {
				if (resp.status === 500) {
					message.error(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				} else if (resp.status === 400) {
					message.error(resp.error)
				} else {
					if (resp.data) {
						this.setState({
							blockDetails: resp.data
						});
					} else {
						message.error('区块信息不存在');
					}
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};
	/**
	 * 复制区块哈希处理
	 * 
	 */
	handleCopyBlockHash() {
		var copyDom = document.querySelector('.copyDom');
		if (copyDom) {
			var range = document.createRange();
			range.selectNode(copyDom);
			let selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(range);
				var successful = document.execCommand('copy');
				selection.removeAllRanges();
				message.success(successful ? '已复制到剪切板' : '复制失败');
			}
		}
	}

	/**
	 * 构建交易记录模块
	 * 
	 */
	buildTransactionList = () => {
		const { blockDetails } = this.state;
		if (blockDetails && blockDetails.transactions) {
			const dataSource: any[] = blockDetails.transactions.map((data: any) => ({
				key: data.txhash,
				...data
			}));

			return <Table scroll={{ x: 700 }} dataSource={dataSource} columns={columns} pagination={false} />;
		}
		return null;
	};

	render() {
		const { blockDetails } = this.state;
		return (
			<div className="container block-info">
				<Spin delay={200} size="large" spinning={this.state.loading}>
					<BreadcrumbComponent data={[ { name: '区块链', href: '/block' }, { name: '区块详情' } ]} />

					<Flex align="start" style={{ marginTop: '2em', lineHeight: '24px' }}>
						<Flex style={{ width: 24, height: 24, flexShrink: 0 }} align="center" jusify="center">
							<Icon style={{ fontSize: 18, color: 'var(--primary-color)' }}>blockchain</Icon>
						</Flex>
						<Flex.Item>
							<div style={{ lineHeight: '24px', fontWeight: 500 }}>
								<label style={{ marginRight: 12 }}>区块高度</label>
								{blockDetails.blocknum || '--'}
							</div>

							<Flex className="block-hash">
								<label>区块哈希</label>
								<Flex.Item>
									{blockDetails.blockhash ? (
										<Flex>
											<div className="copyDom text-ellipsis">{blockDetails.blockhash}</div>
											<div style={{ marginLeft: 12 }}>
												<Button size="small" type="primary" onClick={this.handleCopyBlockHash}>
													复制
												</Button>
											</div>
										</Flex>
									) : (
										'--'
									)}
								</Flex.Item>
							</Flex>
						</Flex.Item>
					</Flex>

					{/* 概况 */}
					<CardComopnent className="overview">
						<div className="card-title">概况</div>
						<ul>
							<li>
								<RadioIcon color="#F092F0" />
								<label>区块哈希</label>
								<span className="text-ellipsis">{blockDetails.blockhash || '--'}</span>
							</li>
							<li>
								<RadioIcon color="#6ED0AB" />
								<label>区块高度</label>
								<span>{blockDetails.blocknum || '--'}</span>
							</li>
							<li>
								<RadioIcon color="#049CFF" />
								<label>交易数量</label>
								<span className="flex-item">{blockDetails.txcount || '--'}</span>
							</li>
							<li>
								<RadioIcon color="RGBA(251, 92, 154, 1)" />
								<label>状态</label>
								<span className="flex-item">{blockDetails.id ? '已确认' : '--'}</span>
							</li>
							<li>
								<RadioIcon color="#C460E1" />
								<label>时间</label>
								<span>{timeStrFormat(blockDetails.createdt)}</span>
							</li>
							<li>
								<RadioIcon color="#FE6C00" />
								<label>数据哈希</label>
								<span className="text-ellipsis">{blockDetails.datahash || '--'}</span>
							</li>
							<li>
								<RadioIcon color="#FE6C00" />
								<label>下个区块</label>
								{blockDetails.prehash ? (
									<Link to={`/block/${blockDetails.prehash}`} className="text-ellipsis">
										{blockDetails.prehash}
									</Link>
								) : (
									'--'
								)}
							</li>
						</ul>
					</CardComopnent>

					{/* 交易记录 */}
					<CardComopnent className="trans-record">
						<div className="card-title">交易记录</div>
						{this.buildTransactionList()}
					</CardComopnent>
				</Spin>
			</div>
		);
	}
}

export default connect((state) => ({
	currentChannel: selectors.currentChannelSelector(state)
}))(withRouter(BlockInfoPage));


const RadioIcon: React.FC<{ color?: string }> = ({ color }) => {
	return (
		<Flex style={{ height: 40, width: 40, flexShrink: 0 }} align="center" jusify="center">
			<svg className="icon" style={{ width: 18, height: 18 }} viewBox="0 0 1024 1024" version="1.1" p-id="1140">
				<path
					d="M511.392323 217.225051c-82.075152 2.534141-151.518384 31.573333-207.075555 87.130505-55.544242 55.557172-84.596364 125.000404-88.384647 208.329697 2.534141 83.329293 31.573333 152.772525 87.130505 208.329696s125.000404 84.596364 208.329697 87.130506c83.330586-2.534141 152.772525-31.573333 208.329697-87.130506 55.555879-55.557172 84.596364-125.000404 87.129212-208.329696-2.532848-83.329293-31.573333-152.772525-87.129212-208.329697-55.558465-55.557172-125.000404-84.596364-208.329697-87.130505z m0-53.023031c98.481131 2.521212 180.557576 36.615758 246.211233 102.270707 65.656242 65.654949 99.749495 147.730101 102.273292 246.212526-2.522505 98.482424-36.615758 180.557576-102.273292 246.212525-65.653657 65.654949-147.730101 99.749495-246.211233 102.272-98.482424-2.521212-180.557576-36.615758-246.212525-102.272C199.524848 693.244121 165.430303 611.167677 162.909091 512.685253c2.521212-98.482424 36.615758-180.557576 102.270707-246.212526 65.654949-65.654949 147.730101-99.749495 246.212525-102.270707z m0 147.730101c56.825535 1.254141 103.537778 21.462626 141.420606 59.332525 37.868606 37.882828 58.078384 84.596364 59.331233 141.420607-1.252848 56.824242-21.462626 103.537778-59.331233 141.407676-37.884121 37.882828-84.596364 58.091313-141.420606 59.344162-56.824242-1.254141-103.537778-21.462626-141.407677-59.344162-37.882828-37.869899-58.091313-84.583434-59.345454-141.407676 1.254141-56.824242 21.462626-103.537778 59.345454-141.420607 37.869899-37.869899 84.583434-58.078384 141.407677-59.332525z"
					fill={color ? color : '#353535'}
				/>
			</svg>
		</Flex>
	);
};
