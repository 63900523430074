/**
 * 格式化Hash字符串
 * 
 * @param hashStr 要格式化的hash字符串
 * @param len 格式化后的长度
 */
const hashStrFormat = (hashStr: string = '', len: number = 18) => {
	if (hashStr.length <= len) return hashStr;
	let _half = (len - (len - 3) % 2) / 2;

	let _prefixStr = hashStr.substring(0, _half);
	let _suffixStr = hashStr.substring(hashStr.length - _half);

	return _prefixStr + '...' + _suffixStr;
};

/**
 * 时间字符串格式化
 * 
 * @param timeStr 
 */
const timeStrFormat = (timeStr: string) => timeStr ? dateFormat(new Date(timeStr)) : '--';

/**
 * 日期对象格式化
 * 
 * @param fmt YYYY-MM-DD hh:mm:ss
 * @param date 
 */
const dateFormat = (date: Date, fmt: string = 'YYYY-MM-DD hh:mm:ss') => {
	let _ret;
	const _opt: any = {
		'Y+': date.getFullYear().toString(), // 年
		'M+': (date.getMonth() + 1).toString(), // 月
		'D+': date.getDate().toString(), // 日
		'h+': date.getHours().toString(), // 时
		'm+': date.getMinutes().toString(), // 分
		's+': date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in _opt) {
		_ret = new RegExp('(' + k + ')').exec(fmt);
		if (_ret) {
			fmt = fmt.replace(_ret[1], _ret[1].length === 1 ? _opt[k] : _opt[k].padStart(_ret[1].length, '0'));
		}
	}
	return fmt;
};

/**
 * 数字格式化
 * 		给数字增加千分位符号
 * 		12345 => 12,345
 * 
 * @param number 
 */
const numberFormat = (number: string | number) => {
	if(!number) return 0;
	var _reg = /(?=(?!\b)(\d{3})+$)/g;
	return number.toString().replace(_reg, ',');
}

export { hashStrFormat, timeStrFormat, dateFormat, numberFormat };
