import React, { CSSProperties } from 'react';

const IconComponent: React.FC<{
	style?: CSSProperties;
}> = ({ children, style }) => {
	const styles = style ? style : undefined;

	return (
		<svg className="icon" aria-hidden="true" style={styles}>
			<use xlinkHref={`#${children}`} />
		</svg>
	);
};

export default IconComponent;
