import React from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { Layout } from 'antd';
import { Header, Footer, Content } from './layout';

import { HomePage } from './pages/home';
import { blockPage, blockInfoPage } from './pages/block';
import { TransactionPage, TransactionInfoPage } from './pages/transaction';
import PageNotFound from './pages/404';

import LoadingPage from './pages/loading';

interface State {
	loading: boolean;
}

class App extends React.Component {
	state: State;
	constructor(props: any) {
		super(props);

		this.state = {
			loading: true
		};
	}

	loaded = () => {
		this.setState({ loading: false });
	};

	render() {
		const { loading } = this.state;
		if (loading) {
			return <LoadingPage onLoaded={this.loaded} />;
		}
		return (
			<Router>
				<Layout>
					<Header />
					<Content>
						<Switch>
							<Route path="/home" component={HomePage} strict />
							<Route path="/block/:id" component={blockInfoPage} strict />
							<Route path="/block" component={blockPage} />
							<Route path="/transaction/:id" component={TransactionInfoPage} strict />
							<Route path="/transaction" component={TransactionPage} />
							<Redirect from="/" to="/home" />
							<Route component={PageNotFound} />
						</Switch>
					</Content>
					<Footer />
				</Layout>
			</Router>
		);
	}
}

export default App;
