import React from 'react';
import { Breadcrumb } from 'antd';

import '../static/css/components/breadcrumb.css';
import { Icon } from '.';
import { useHistory } from 'react-router';
import Flex from './flex';

interface BreadcrumbData {
	name: string;
	href?: string;
}

const BreadcrumbComponent: React.FC<{ data: BreadcrumbData[] }> = ({ data }) => {
	const history = useHistory();
	return (
		<div className="breadcrumb-component flex">
			<Flex style={{ width: 24, height: 24 }} align="center" jusify="center">
				<Icon style={{ fontSize: 18 }}>location</Icon>
			</Flex>
			<Breadcrumb
				separator={
					<Flex style={{ height: 22, display: 'inline-flex' }} align="center" jusify="center">
						<Icon style={{ fontSize: 14 }}>arrow_right</Icon>
					</Flex>
				}
			>
				{data.map((item, index) => (
					<Breadcrumb.Item
						key={index}
						href="javascript:;"
						onClick={() => {
							if (item.href) history.push(item.href);
						}}
					>
						<span>{item.name}</span>
					</Breadcrumb.Item>
				))}
				<Breadcrumb.Item />
			</Breadcrumb>
		</div>
	);
};

export default BreadcrumbComponent;
