/**
 *    SPDX-License-Identifier: Apache-2.0
 */

const namespaces = 'app';

const AUTH_TOKEN = `${namespaces}/AUTH_TOKEN`;

const CURRENT_CHANNEL = `${namespaces}/CURRENT_CHANNEL`;
const ERROR_MESSAGE = `${namespaces}/ERROR_MESSAGE`;
const DASHBOARD_STATS = `${namespaces}/DASHBOARD_STATS`;
const SEARCH = `${namespaces}/SEARCH`;

const BLOCK_LIST = `${namespaces}/BLOCK_LIST`;
const BLOCK_DETAILS = `${namespaces}/BLOCK_DETAILS`;
const TRANSACTION_LIST = `${namespaces}/TRANSACTION_LIST`;
const LATEST_BLOCK = `${namespaces}/LATEST_BLOCK`;
const LATEST_TRANSACTION = `${namespaces}/LATEST_TRANSACTION`;

export default {
	AUTH_TOKEN,
	CURRENT_CHANNEL,
	ERROR_MESSAGE,
	DASHBOARD_STATS,
	SEARCH,
	BLOCK_LIST,
	BLOCK_DETAILS,
	TRANSACTION_LIST,
	LATEST_BLOCK,
	LATEST_TRANSACTION
};
