import React from 'react';
import { Flex } from "../components";
import { Col, Row } from 'antd';
import qr_wx from "../static/images/footer-qrcode-wx.jpg";
import qr_wxmp from "../static/images/footer-qrcode-wxmp.jpg";
import IconComponent from '../components/Icon';

class LayoutFooter extends React.Component {
	render() {
		return (
			<footer>
				<div className="container">
					<Row gutter={24} align="top">
						<Col xs={24} xl={6} md={8} style={{ marginTop: 24 }}>
							<Flex
								direction="column"
								align="start">
								<h2>帮助与支持</h2>
								<ul>
									<li>
										<a target="_blank" href="https://www.chengxinchain.cn/">存证平台</a>
									</li>
									<li>
										<a target="_blank" href="http://www.netcourt.gov.cn/portal/main/domain/index.htm">网上法庭</a>
									</li>
									<li>
										<a target="_blank" href="http://www.npc.gov.cn/wxzl/wxzl/2004-10/20/content_334609.htm">中华人民共和国电子签名法</a>
									</li>
									<li>
										<a target="_blank" href="http://www.court.gov.cn/zixun-xiangqing-116981.html">互联网存证法规</a>
									</li>
								</ul>
							</Flex>
						</Col>
						<Col xs={24} xl={6} md={8} style={{ marginTop: 24 }}>
							<Flex direction="column" align="start">
								<h2>联系我们</h2>
								<ul>
									<li>
										<Flex align="start">
											<div style={{ flexShrink: 0, marginRight: 6 }}>
												<IconComponent>location</IconComponent>
											</div>
											<div style={{ textAlign: 'left' }}>江苏省苏州市相城区阳澄湖东路8号 相城区行政中心4号楼</div>
										</Flex>
									</li>
									<li>
										<Flex align="start">
											<div style={{ flexShrink: 0, marginRight: 6 }}>
												<IconComponent>phone</IconComponent>
											</div>
											<div style={{ textAlign: 'left' }}>13182677030 18118127710</div>
										</Flex>
									</li>
								</ul>
							</Flex>
						</Col>
						<Col xs={24} xl={12} md={8} style={{ marginTop: 44 }} className="xs-down">
							<Flex>
								<div className="qr-wrap">
									<img src={qr_wxmp} alt="" />
									<p>相城公证处</p>
								</div>
								<div className="qr-wrap">
									<img src={qr_wx} alt="" />
									<p>公证处陈杰</p>
								</div>
							</Flex>
						</Col>
					</Row>
				</div>
				<div className="copyright">
					©2020 苏州市相城公证处澄信链平台 版权所有 ICP备案号：苏ICP备2020050161号-1
				</div>
			</footer>
		);
	}
}

export default LayoutFooter;
