// 缓存页面滚动位置
// 页面滚动位置设置


type CacheScrollTop = {
    [name: string]: number
}
const KEY = "scroll_top"

/**
 * 获取pathname对应页面的缓存滚动位置
 * 
 * @param pathname 
 */
const getChachedPageScrollTop = (pathname: string) => {
    let _scrollTopStr = sessionStorage.getItem(KEY);
    if (_scrollTopStr) {
        let _cacheScrollTop: CacheScrollTop = JSON.parse(_scrollTopStr);
        return _cacheScrollTop[pathname] || 0;
    }
    return 0;
};

/**
 * 缓存pathname对应页面的缓存滚动位置
 * 
 * @param pathname 
 */
const cachePageScrollTop = (pathname: string) => {
    let _obj: CacheScrollTop = {}
    let _scrollTop = getScrollTop();
    let _scrollTopStr = sessionStorage.getItem(KEY);
    if (_scrollTopStr) {
        _obj = JSON.parse(_scrollTopStr);
    }
    _obj[pathname] = _scrollTop
    sessionStorage.setItem(KEY, JSON.stringify(_obj))
}

/**
 * 滚动到pathname对应页面的缓存位置
 * 
 * @param pathname 
 */
const scrollToCachedPosition = (pathname: string) => {
    setTimeout(() => {
        window.scrollTo({ top: getChachedPageScrollTop(pathname) })
    });
}

/**
 * 滚动到顶部
 */
const scrollToTop = () => {
    setTimeout(() => {
        window.scrollTo({ top: 0 })
    });
}


/**
 * 获取当前滚动高度
 */
const getScrollTop = () => {
    return document.documentElement.scrollTop || document.body.scrollTop;
}

export { getChachedPageScrollTop, cachePageScrollTop, scrollToCachedPosition, scrollToTop }