/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import types from './types';
import { BlockListState, TransactionListState } from './reducer';

const getAuthToken = (token: string) => ({
	type: types.AUTH_TOKEN,
	payload: token
});
const getChannel = (channel: string) => ({
	type: types.CURRENT_CHANNEL,
	payload: channel
});
const getDashStats = (dashStats: any) => ({
	type: types.DASHBOARD_STATS,
	payload: dashStats
});

const getErroMessage = (errorMessage: string) => ({
	type: types.ERROR_MESSAGE,
	payload: errorMessage
});

const getBlockList = (blockList: BlockListState) => ({
	type: types.BLOCK_LIST,
	payload: blockList
});
const getBlockDetails = (blockDetails: any) => ({
	type: types.BLOCK_DETAILS,
	payload: blockDetails
});

const getTransactionList = (transactionList: TransactionListState) => ({
	type: types.TRANSACTION_LIST,
	payload: transactionList
});
const getLatestBlock = (data: any[]) => ({
	type: types.LATEST_BLOCK,
	payload: data
});
const getLatestTransaction = (data: any[]) => ({
	type: types.LATEST_TRANSACTION,
	payload: data
});

export default {
	getAuthToken,
	getChannel,
	getDashStats,
	getErroMessage,
	getBlockList,
	getBlockDetails,
	getTransactionList,
	getLatestBlock,
	getLatestTransaction
};
