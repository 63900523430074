
const env: "DEV" | "PROD" | "TEST" = "PROD";
console.log('当前环境为：', env);


const apiRootMap = {
    "DEV": "",
    "PROD": '',
    "TEST": ''
}
const networkMap = {
    "DEV": "first-network",
    "PROD": "first-network",
    // "PROD": 'cunan-network',
    "TEST": ''
}

type OrgType = "Org1MSP" | "Org2MSP" | "Org3MSP";
// TODO:未记录的值 OrdererMSP
const orgMap: { [keyName: string]: string } = {
    "Org1MSP": "相城公证处1",
    "Org2MSP": "相城公证处2",
    "Org3MSP": "相城公证处3"
}


class Config {
    static getApiRoot() {
        return apiRootMap[env]
    }

    static getNetwork() {
        return networkMap[env]
    }

    static getOrgName(key: OrgType) {
        return orgMap[key];
    }

}
export default Config