import React, { CSSProperties } from 'react';
import '../static/css/components/flex.css';

interface CommonProps {
	className?: string;
	style?: CSSProperties;
	onClick?: () => void;
}
interface FlexProps extends CommonProps {
	align?: 'start' | 'center' | 'end';
	jusify?: 'start' | 'center' | 'end' | 'between' | 'stretch';
	direction?: 'row' | 'column';
	wrap?: boolean;
}

interface FlexItemProps extends CommonProps {}

interface FlexComponent extends React.FC<FlexProps> {
	Item: React.FC<FlexItemProps>;
}

const Flex: FlexComponent = (props) => {
	const { children, className, style, align, jusify, direction = 'row', wrap = false } = props;
	const { onClick } = props;

	let _classes = `flex flex-direction-${direction}`;
	if (align) _classes += ` flex-align-${align}`;
	if (jusify) _classes += ` flex-justify-${jusify}`;
	if (wrap) _classes += ` flex-wrap`;

	if (className) _classes += ` ${className}`;
	return (
		<div className={_classes} style={style} onClick={onClick}>
			{children}
		</div>
	);
};

Flex.Item = (props) => {
	const { children, style, className } = props;
	const { onClick } = props;
	let _classes = 'flex-item';
	if (className) _classes += ` ${className}`;
	return (
		<div className={_classes} style={style} onClick={onClick}>
			{children}
		</div>
	);
};

export default Flex;
