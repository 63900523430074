/**
 *    SPDX-License-Identifier: Apache-2.0
 */
import actions from './actions';
import { get, post } from '../tools/request';
import Auth from "../tools/auth"

const login = ({ user, password, network }: { [name: string]: string }) => (dispatch: any) =>
	post('/auth/login', { user, password, network })
		.then((resp: any) => {
			Auth.authenticateUser(resp.token);
			dispatch(actions.getAuthToken(resp.token))
			return { status: 'Success' };
		})
		.catch(() => {
			return { status: 'Error', message: "Authorization failure" };
		});

/**
 * get current channel
 * 
 */
const channel = () => (dispatch: any) =>

	// 固定channel
	dispatch(actions.getChannel("b53c43c02ea6dcaa4cd2dcce88bcfe4cbe53d732062258a3c0e85071c72c42d0"));
	
	// get('/api/curChannel')
	// 	.then((resp: any) => {
	// 		if (resp.status === 500) {
	// 			dispatch(
	// 				actions.getErroMessage(
	// 					'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
	// 				)
	// 			);
	// 		} else if (resp.status === 400) {
	// 			dispatch(actions.getErroMessage(resp.error));
	// 		} else {
	// 			dispatch(actions.getChannel(resp.currentChannel));
	// 		}
	// 	})
	// 	.catch((error) => {
	// 		console.error(error);
	// 	});

/**
 * get dash status
 * 
 * @param channel 
 */
const dashStats = (channel: string) => (dispatch: any) =>
	get(`/api/status/${channel}`)
		.then((resp: any) => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getDashStats(resp));
			}
		})
		.catch((error) => {
			console.error(error);
		});

/**
 * get block list
 * 
 * @param channel 
 */
const blockList = (page: number, prePage: number) => (dispatch: any) =>
	get(`/api/blocks?page=${page}&prePage=${prePage}`)
		.then((resp: any) => {
			const { code, block, total } = resp
			if (code === 200) {

				// 数据处理
				// 给每条数据增加key字段用于渲染表格
				let rows: any[] = block.map((row: any) => ({
					key: row.datahash,
					...row
				}));

				dispatch(actions.getBlockList({
					page: page,
					total,
					rows
				}));
				if (page === 1) {
					dispatch(actions.getLatestBlock(rows))
				}
			}
		})
		.catch((error) => {
			console.error(error);
		});

/**
 * get transaction list
 * 
 * @param channel 
 */
const transactionList = (page: number, prePage: number) => (dispatch: any) =>
	get(`/api/transactions?page=${page}&prePage=${prePage}`)
		.then((resp: any) => {
			const { code, transactions, total } = resp
			if (code === 200) {
				// 数据处理
				// 给每条数据增加key字段用于渲染表格
				let rows: any[] = transactions.map((row: any) => ({
					key: row.txhash,
					...row
				}));

				dispatch(actions.getTransactionList({
					page: page,
					total,
					rows
				}));

				if (page === 1) {
					dispatch(actions.getLatestTransaction(rows))
				}
			}
		})
		.catch((error) => {
			console.error(error);
		});

export { dashStats, channel, transactionList, blockList, login };
