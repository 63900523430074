import React from 'react';
import { Table, Button } from 'antd';

import '../../static/css/block.css';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { BreadcrumbComponent } from '../../components';
import { connect } from 'react-redux';

import { operations, selectors } from '../../redux';
import { numberFormat, timeStrFormat, hashStrFormat } from '../../tools/format';
import { scrollToCachedPosition, cachePageScrollTop } from '../../tools/page_scroll';
import { AppState, BlockListState } from '../../redux/reducer';

const columns: ColumnsType<any> = [
	{
		title: '区块高度',
		dataIndex: 'blocknum',
		key: 'blocknum',
		render: (text) => <Link to={`/block/${text}`}>{text}</Link>
	},
	{
		title: '区块哈希',
		dataIndex: 'blockhash',
		key: 'blockhash',
		render: (text) => <Link to={`/block/${text}`}>{hashStrFormat(text, 40)}</Link>
	},
	{
		title: '存证数量',
		dataIndex: 'txcount',
		key: 'txcount'
	},
	{
		title: '出块时间',
		dataIndex: 'createdt',
		key: 'createdt',
		render: (text) => timeStrFormat(text)
	}
];

interface State {
	dataSource?: any[];
	loading: boolean;
}
class BlockchainPage extends React.Component<{
	blockList: BlockListState;
	currentChannel: string;
	getBlockList: (page: number, prePage: number) => Promise<void>;
}> {
	state: State;
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false
		};
	}
	async componentDidMount() {
		scrollToCachedPosition('block');
		const { blockList } = this.props;
		if (blockList.rows.length <= 0) {
			this.loadBlock(blockList.page);
		}
	}

	async loadBlock(page: number, prePage: number = 15) {
		const { getBlockList } = this.props;
		this.setState({ loading: true });
		await getBlockList(page, prePage);
		this.setState({ loading: false });
	}

	componentWillUnmount() {
		cachePageScrollTop('block');
	}

	handlePageChange = (page: number, pageSize?: number) => {
		this.loadBlock(page, pageSize);
	};

	render() {
		const { blockList } = this.props;

		return (
			<div className="container block">
				<BreadcrumbComponent data={[ { name: '区块链' } ]} />
				<div className="table-container">
					{blockList && <div className="total">全部区块（{numberFormat(blockList.total)}个区块）</div>}
					<Table
						scroll={{ x: 860 }}
						size="small"
						dataSource={blockList.rows || []}
						columns={columns}
						loading={this.state.loading}
						pagination={{
							total: blockList.total,
							current: blockList.page,
							position: [ 'topRight', 'bottomRight' ],
							defaultPageSize: 15,
							hideOnSinglePage: true,
							onChange: this.handlePageChange,
							showQuickJumper: {
								goButton: (
									<Button size="small" type="primary" style={{ fontSize: 13, marginLeft: 6 }}>
										跳转
									</Button>
								)
							},
							pageSizeOptions: [ '15', '30', '45', '60' ]
						}}
					/>
				</div>
			</div>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			blockList: selectors.blockListSelector(state),
			currentChannel: selectors.currentChannelSelector(state)
		};
	},
	{
		getBlockList: operations.blockList
	}
)(BlockchainPage);
