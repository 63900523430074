/**
 *    SPDX-License-Identifier: Apache-2.0
 */
import { AppState } from "./reducer"

export const authSelector = (state: any) => state.authToken;

export const currentChannelSelector = (state: any) => state.currentChannel;
export const errorMessageSelector = (state: any) => state.errorMessage;
export const dashStatsSelector = (state: any) => state.dashStats;

export const blockListSelector = (state: AppState) => state.blockList;
export const blockDetailsSelector = (state: any) => state.blockDetails;
export const transactionListSelector = (state: AppState) => state.transactionList;


export const latestBlockSelector = (state: AppState) => state.latestBlock;
export const latestTransactionSelector = (state: AppState) => state.latestTransaction;
